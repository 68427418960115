@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard-dynamic-subset.css');
@font-face {
  font-family: "Pretendard";
  src: url("../src/resources/fonts/Pretendard-Regular.otf") format("otf");
}
html,
body {
  font-family: "Pretendard", sans-serif;
}

body,
header,
h2,
p {
  margin: 0;
  padding: 0;
}
button {
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
}
