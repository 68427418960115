h3 {
  margin: 0;
  padding: 0;
  color: var(--black, #252525);
  text-align: center;
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  line-height: 125%; /* 35px */
}
h3.month {
  font-weight: 700;
  letter-spacing: 0.84px;
  margin-right: 12px;
}
h3.year {
  font-weight: 200;
  letter-spacing: 0.56px;
}
h2 {
  /* Heading/H2/medium */
  font-family: Pretendard;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px; /* 128.571% */
}
.cover-img {
  position: absolute;
  width: 100%;
  height: 176px;
  object-fit: cover;
  left: 0;
  top: 0;
  z-index: -1;
}
h2.title {
  color: #fff;
  width: 221px;
  margin: 13px 16px;
}
header {
  display: flex;
  align-items: flex-end;
  width: 100%;
  height: 176px;
  position: relative;
}
main {
  padding: 18px 2px;
}

.line {
  width: calc(100% - 190px);
  height: 1px;
  background: rgba(0, 0, 0, 0.6);
  margin-right: 15px;
}

.empty {
  color: var(--Text-Secondary, rgba(0, 0, 0, 0.38));
  font-family: Pretendard;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px; /* 127.778% */
  letter-spacing: -0.036px;
  margin-top: 14px;
}
.dc-box {
  display: flex;
  align-items: center;
}
.sbc-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.fe-box {
  display: flex;
  justify-content: flex-end;
}
.caption {
  color: var(--color-text-medium-emphasis, rgba(0, 0, 0, 0.6));
  /* Caption/C1/regular */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 18px */
}
.today {
  color: var(--Darkmode-Text-Title-Primary, #fff);

  /* Caption/C1/semi bold */
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 19.2px */
  display: flex;
  width: 50px;
  height: 16px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: var(--color-key-secondary, #47b881);
  margin-bottom: 5px;
}

.p1 {
  color: #222b45;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/P1/semibold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 160%; /* 25.6px */
  letter-spacing: -0.2px;
}
.cal-m {
  color: var(--color-primary-default, #ff7e43);
  text-align: center;

  /* Caption/C2/medium */
  font-family: Pretendard;
  font-size: 10px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.5px; /* 165% */
  letter-spacing: 0.01px;
}
.cal-m.now {
  color: #47b881;
}
.cal-y {
  color: var(--color-primary-default, #ff7e43);
  text-align: center;

  /* Heading/H1/bold */
  font-family: Pretendard;
  font-size: 29px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 43.5px */
}
.cal-y.now {
  color: #47b881;
}
.body1 {
  color: #ff7e43;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/P1/medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}

button.download {
  display: inline-flex;
  padding: 12px 36px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 6px;
  background: var(--brand-gradiant-2, linear-gradient(91deg, #ff7e43 0%, #ff8c57 46.15%, #fdd835 112.16%));
  width: calc(100% - 40px);
}
.down-typo {
  color: #fff;
  text-align: center;
  font-feature-settings: "clig" off, "liga" off;

  /* Body/P1/bold */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
}
.full-size-fixed-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.full-size-fixed-box > div {
  min-width: 375px;
  max-width: 600px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background-color: rgba(255, 255, 255, 0.5);
}
.modal-box {
  display: flex;
  width: 312px;
  flex-direction: column;
  align-items: flex-end;
  border-radius: 6px;
  border: 1px solid var(--dialog, #f57f17);
  background: var(--goodduck-sys-surface, #fff);
}
.modal-header {
  display: flex;
  padding: 24px 24px 0px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}
.modal-title {
  color: rgba(0, 0, 0, 0.87);
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.032px;
}
.modal-content {
  color: var(--Text-Primary, rgba(0, 0, 0, 0.6));
  font-family: Pretendard;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 150% */
  letter-spacing: 0.012px;
}
.modal-footer {
  display: flex;
  padding: 24px 24px 24px 8px;
  align-items: center;
  gap: 8px;
}
.modal-footer button {
  display: flex;
  padding: 10px 12px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--color-primary-default, #ff7e43);
  text-align: center;
  font-family: Pretendard;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
  letter-spacing: -0.021px;
}
.modal-cancel {
  margin-right: 8px;
}

.error-box {
  padding: 38px 30px;
  min-width: 375px;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
}
.error-box > img {
  width: 113px;
  flex-shrink: 0;
}
.error-text {
  color: var(--color-primary-default, #ff7e43);

  /* Heading/H4/bold */
  font-family: Pretendard;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 30px */
  margin: 20px 0;
}
.error-desc {
  color: var(--Text-Secondary, rgba(0, 0, 0, 0.38));
  font-feature-settings: "clig" off, "liga" off;

  /* Body/P1/medium */
  font-family: Pretendard;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 24px */
}
